<template>
  <div class="container-fluid">
    <Header
      v-if="state.loaded"
      header="Home"
      title="Profile"
      :state="state"
    />

      <router-view/>
  </div>
</template>

<script>
import Header from "../../components/pageTitle/index";

export default {
  components: {
    Header,
  },
  computed:{
      state(){
          return this.$store.state.profile
      }
  },
  methods: {
  }
};
</script>
<style>
.mt-20 {
  margin-top: 14rem !important;
  margin-bottom: 13rem !important;
}
@media only screen and (max-width: 600px) {
  .mt-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
}
</style>
